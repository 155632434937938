/* istanbul ignore file */
/* eslint-disable max-len */

export const filenameFormat = '^[a-zA-Z0-9._-]*';
export const VALIDATION_MAXFILECOUNT = (count: number): string =>
  `${count} file count exceeded. Please select fewer files and try again.`;
export const VALIDATION_MAXFILESIZE = (size: number): string =>
  `${size}MB file size exceeded. Please reduce the size of the file and try again.`;
export const VALIDATION_MAXFILELENGTHSIZE = (maxLength: number): string =>
  `${maxLength} character count exceeded. Please rename your file.`;
export const VALIDATION_BADFILENAMEFORMAT =
  'Invalid character. Acceptable characters are uppercase letters (A-Z), lowercase letters (a-z), numbers (0-9), underscore (_), hyphens (-) and periods (.). Please rename your file.';
export const VALIDATION_BADFILEEXTENSION =
  'Invalid file type. Please try again with a CSV file.';
export const VALIDATION_BADFILEENDING = (endingList: string): string =>
  `Invalid data type. Acceptable data types are ${endingList}. Please rename your file.`;
export const VALIDATION_INVALIDLEAID = (): string =>
  `User does not have permission to upload files for this LEA.  Please ensure the LEA ID in the file is correct.`;
export const VALIDATION_INVALIDPOTENTIALLEAID = (): string =>
  `"Invalid file name.  Please review naming convention above."`;

export const SOLUTION_SUCCESS =
  'Click “Upload File(s)” to upload acceptable files, then correct the files with issues above according to the error messages, and upload the corrected files as a new batch.';
export const SOLUTION_FAILURE =
  'Click “Clear Files”, correct the file issues above according to the error messages, then upload all files as a new batch.';
export const SOLUTION_NOLEAID =
  'Select "Clear Files", rename your file and try uploading your file again.';
export const SOLUTION_PROHIBITEDLEA =
  'Select "Clear Files", use the correct LEA ID in the file name and try uploading your file again.';

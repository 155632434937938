/* eslint-disable max-len */
import { ReactElement, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ActionButton from '../../components/ActionButton/ActionButton';
import ErrorDetails from '../../components/ErrorDetails/ErrorDetails';
import FileDetailPage from '../../components/FileDetailPage/FileDetailPage';
import FileErrorDetails from '../../components/FileErrorDetails/FileErrorDetails';
import InfoPanel from '../../components/InfoPanel/InfoPanel';
import LabelAndValue from '../../components/LabelAndValue/LabelAndValue';
import Pill from '../../components/Pill/Pill';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectSelectedDistrict } from '../../redux/slices/appSlice';
import {
  selectFileDetail,
  selectFileLoading,
} from '../../redux/slices/fileSlice';
import {
  downloadFileById,
  fetchFileById,
} from '../../redux/thunks/filesThunks';
import { abortPromiseOnUnmount } from '../../services/base.service';
import { appRoutePaths } from '../../services/route.service';
import { splitStringByPipe } from '../../testing/fakers/fileHelpers';
import FileSchemaErrors from '../../types/HeadersForErrors';
import { PillType } from '../../types/PillType';
import { SchemaErrors } from '../../types/SchemaErrors';
import { formatToDateString } from '../../utilities/dateUtilities';
import './FileDetail.css';

export const Upload_Success_Message = 'File validation successful';

const FileDetail = (): ReactElement => {
  const { fileId, leaId } = useParams();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectFileLoading);
  const fileDetails = useAppSelector(selectFileDetail);
  const selectedDistrict = useAppSelector(selectSelectedDistrict);

  useEffect(() => {
    let promise: unknown = undefined;
    if (fileId && selectedDistrict.districtId) {
      promise = dispatch(
        fetchFileById({ fileId, leaId: selectedDistrict.districtId })
      );
    }
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch, fileId, leaId, selectedDistrict]);

  const {
    id,
    uploadedBy,
    fileName,
    uploadDateTimeUtc,
    status,
    errorCode,
    canDownload,
    processingData,
  } = fileDetails;

  const headersForError = FileSchemaErrors[errorCode as keyof SchemaErrors];
  const statusText = status;

  const handleDownload = async (): Promise<void> => {
    if (fileId && leaId) {
      dispatch(downloadFileById({ fileId: id, fileName, leaId }));
    }
  };

  return (
    <FileDetailPage
      heading={fileName?.split(';').pop() ?? ''}
      pageClass="file-details"
      isLoading={isLoading}
      loadingDataId="file-detail-loader"
      loadingText="Getting your File Details"
      backBarLocation={appRoutePaths.SubmissionStatus}
    >
      <div className="file-details--content">
        <div className="file-details--container">
          <LabelAndValue
            cypressDataId="file-details-upload-date-time"
            showColonOnLabel={true}
            displayName="Date Uploaded"
            value={formatToDateString(uploadDateTimeUtc)}
          />
          <LabelAndValue
            cypressDataId="file-details-uploaded-by"
            showColonOnLabel={true}
            displayName="Uploaded By"
            value={uploadedBy}
          />
          <LabelAndValue
            cypressDataId="file-details-records-processed"
            showColonOnLabel={true}
            displayName="Number of Records"
            value={(
              processingData.successfulRecordsProcessed +
              processingData.failedRecordsProcessed
            ).toString()}
          />
        </div>
        <div
          className="file-details--status"
          data-status={status}
          data-cy="file-details-status"
        >
          <label>File Status:</label>{' '}
          <Pill
            status={status}
            value={statusText}
            pillType={PillType.FILE_STATUS}
          />
        </div>

        {(status as string) == 'Pending' && (
          <div>
            {!isLoading && status && (
              <InfoPanel
                containerClass={'file-details--status'}
                classForParentPanel={'info--panel--parent--pending'}
                message={
                  'Your file is pending validation. Please check again later.'
                }
                panelForIcon={'info--panel--pending--icon'}
              />
            )}
          </div>
        )}
        {(status as string) == 'Success' && (
          <div className="file-details--status">
            {!isLoading && status && (
              <InfoPanel
                containerClass={'file-details--status'}
                message={Upload_Success_Message}
              />
            )}
          </div>
        )}

        {(status as string) == 'Failed' && (
          <>
            <FileErrorDetails
              dataCy="file-details-generic-message"
              title="File validation failed."
              subtitle="Schema errors were found. Please correct the data and try again."
              instructionsToFix={<p></p>}
              errorCount={processingData.failedRecordsProcessed}
            />
            {canDownload && (
              <ActionButton
                dataTestId="download-file"
                onClick={() => handleDownload()}
                classes="file-details--download"
                tooltipText="Download Error File"
              >
                <span className="download-text">Download Error File</span>
              </ActionButton>
            )}
          </>
        )}

        {headersForError && (
          <ErrorDetails
            dataCy="file-details-error-container"
            title="File column headers are not as expected"
            subtitle="Invalid column headers. Please correct the column headers and
                  try again."
            instructionsToFix={
              <>
                <p>
                  Correct the file to match the following column headers, then
                  re-upload the file.
                </p>
                <ol className="file-details--status--details--header-list">
                  {headersForError.map((header: string) => (
                    <li key={header}>{header}</li>
                  ))}
                </ol>
              </>
            }
          />
        )}
      </div>
      <div className="file-details--content">
        {status == 'Failed' && (
          <table cellSpacing={0} className="page-view-table">
            <thead>
              <tr>
                {fileDetails.processingData.detailHeaders ? (
                  splitStringByPipe(
                    fileDetails.processingData.detailHeaders
                  ).map((header, index) => <th key={index}>{header}</th>)
                ) : (
                  <></>
                )}
              </tr>
            </thead>
            <tbody>
              {fileDetails.processingData.errorDetails?.map(
                (errorDetail, index) => (
                  <tr key={index}>
                    {splitStringByPipe(errorDetail).map(
                      (detail, detailIndex) => (
                        <td key={detailIndex}>{detail}</td>
                      )
                    )}
                  </tr>
                )
              )}
            </tbody>
          </table>
        )}
      </div>
    </FileDetailPage>
  );
};

export default FileDetail;

/* eslint-disable max-len */
import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SingleValue } from 'react-select';
import ActionButton from '../../components/ActionButton/ActionButton';
import InfoPanel from '../../components/InfoPanel/InfoPanel';
import LabelAndValue from '../../components/LabelAndValue/LabelAndValue';
import Loader from '../../components/Loader/Loader';
import NavigationButton from '../../components/NavigationButton/NavigationButton';
import NavigationPage from '../../components/NavigationPage/NavigationPage';
import Panel from '../../components/Panel/Panel';
import Pill from '../../components/Pill/Pill';
import StyledSingleDropdown from '../../components/StyledSingleDropdown/StyledSingleDropdown';
import { useConfirmationModalContext } from '../../hooks/useConfirmationModalContext';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectUserDetailLoading,
  selectUserDetailsFormModel,
  selectUserLoading,
} from '../../redux/slices/userSlice';
import {
  fetchUserDetailsViewModelById,
  fetchUserDetailsViewModelByIdAndDistrictId,
} from '../../redux/thunks/userThunks';
import {
  abortPromiseOnUnmount,
  getCurrentUserAccess,
  isValidCode,
} from '../../services/base.service';
import { appRoutePaths } from '../../services/route.service';
import { canUserAccessRoute } from '../../services/security.service';
import { activateUser, deactivateUser } from '../../services/user.service';
import { ApiResponseModel } from '../../types/ApiResponseModel';
import { PanelType } from '../../types/PanelType';
import { PillType } from '../../types/PillType';
import { ReactSelectOption } from '../../types/ReactSelectOption';
import { UserDetailsFormModel } from '../../types/UserDetailsViewModel';
import { UserStatus } from '../../types/UserStatus';
import { UserType } from '../../types/UserType';
import { setStyledDropdownSingleValue } from '../../utilities/helperUtilities';
import {
  getDistrictDisplay,
  getRoleDisplayText,
  isActive,
  isCurrentUser,
  isStateUser,
} from '../../utilities/userUtilities';
import './UserDetail.css';

const UserDetail = (): ReactElement => {
  const { userId } = useParams();

  /* istanbul ignore next */
  const getUserId = (): string => userId || '';

  const dispatch = useAppDispatch();
  const confirmContext = useConfirmationModalContext();
  const isLoading = useAppSelector(selectUserLoading);
  const isDetailLoading = useAppSelector(selectUserDetailLoading);
  const userDetails: UserDetailsFormModel = useAppSelector(
    selectUserDetailsFormModel
  );
  const [apiMessage, setAPIMessage] = useState<string | undefined>(undefined);
  const districtSet =
    userDetails.userType === UserType.District
      ? userDetails.assignedDistricts
      : userDetails.currentDistrict
      ? [userDetails.currentDistrict]
      : [];

  useEffect(() => {
    let promise: unknown = undefined;
    if (userId) {
      promise = dispatch(fetchUserDetailsViewModelById(userId));
    }
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch, userId]);

  const userIsActive =
    userId !== undefined && userDetails.userStatus === UserStatus.Active;

  const handleActivate = async (id: string): Promise<void> => {
    confirmContext.setOptions({
      title: 'Activate user for district?',
      content: (
        <div className="confirm-container">
          Are you sure you want to activate this user for the district &quot;
          {getCurrentUserAccess()?.currentDistrict?.name}&quot;?
        </div>
      ),
      confirmText: 'Yes, activate user',
      cancelText: 'No, cancel',
      onOk: async (): Promise<ApiResponseModel<unknown>> => {
        const response = await activateUser(id);
        if (isValidCode(response.status)) {
          setAPIMessage('User activated successfully.');
          dispatch(fetchUserDetailsViewModelById(id));
        } else {
          setAPIMessage(undefined);
        }
        return response;
      },
    });

    await confirmContext.showConfirmation();
  };

  const handleDeactivate = async (id: string): Promise<void> => {
    confirmContext.setOptions({
      title: 'Deactivate user for district?',
      content: (
        <div className="confirm-container">
          Are you sure you want to deactivate the user for the district &quot;
          {getCurrentUserAccess()?.currentDistrict?.name}&quot;?
        </div>
      ),
      confirmText: 'Yes, deactivate user',
      cancelText: 'No, cancel',
      onOk: async (): Promise<ApiResponseModel<unknown>> => {
        const response = await deactivateUser(id);
        if (isValidCode(response.status)) {
          setAPIMessage('User deactivated successfully.');
          dispatch(fetchUserDetailsViewModelById(id));
        } else {
          setAPIMessage(undefined);
        }
        return response;
      },
    });

    await confirmContext.showConfirmation();
  };

  const buttonRender = (id?: string): ReactElement =>
    userIsActive ? (
      <NavigationButton
        dataTestId="edit-button"
        route={appRoutePaths.UserEdit(id)}
        classes="button no-wrap-text"
        visibleOverride={canUserAccessRoute(appRoutePaths.UserEdit())}
        tooltipText="Edit User"
      >
        <>Edit User</>
      </NavigationButton>
    ) : (
      <ActionButton
        dataTestId="activate-button"
        onClick={() => userId && handleActivate(getUserId())}
        tooltipText="Activate User"
      >
        <span>Activate User</span>
      </ActionButton>
    );

  const headerNameWithPill = isLoading ? (
    ''
  ) : (
    <>
      {userDetails.name}
      <div className="page-pill-heading">
        <Pill
          value={userDetails.userStatus}
          pillType={PillType.USER_STATUS}
          status={userDetails.userStatus}
        />
      </div>
    </>
  );

  const handleViewingDistrictChange = async (
    selected: SingleValue<ReactSelectOption>
  ): Promise<void> => {
    /* istanbul ignore next */
    const value = selected?.value || '';

    // No change was made
    if (value === userDetails.viewingDistrictId) {
      return Promise.resolve();
    }

    dispatch(
      fetchUserDetailsViewModelByIdAndDistrictId({
        userId: getUserId(),
        viewingDistrictId: value,
      })
    );

    return Promise.resolve();
  };

  return (
    <NavigationPage
      heading={headerNameWithPill}
      pageClass="user-management--detail"
      isLoading={isLoading}
      loadingDataId="user-detail-loader"
      loadingText="Getting user details"
      backBarLocation={appRoutePaths.UserList}
      contentClass="user-detail-layout"
      headerChild={buttonRender(userId)}
    >
      {apiMessage && (
        <InfoPanel message={apiMessage} containerClass="user-detail-success" />
      )}

      <Panel panelType={PanelType.INFO} heading="General Information">
        <LabelAndValue
          cypressDataId="user-email"
          displayName="Email"
          value={userDetails.email}
        />
        <LabelAndValue
          cypressDataId="user-district-access"
          displayName="District(s)"
          value={getDistrictDisplay(districtSet)}
        />
        {isStateUser(userDetails.userType) && (
          <LabelAndValue
            cypressDataId="user-application-role"
            displayName="User Role"
            value={userDetails.applicationRole}
          />
        )}
        {!isCurrentUser(getUserId()) && isActive(userDetails.userStatus) && (
          <div>
            <ActionButton
              dataTestId="deactivate-button"
              classes="button--small"
              onClick={() => userId && handleDeactivate(getUserId())}
              tooltipText="Deactivate User"
            >
              Deactivate User
            </ActionButton>
          </div>
        )}
      </Panel>
      {userDetails.userType === UserType.State && (
        <div className="list-display" data-testid="state-list">
          <h2>Data Domains</h2>
          {userDetails.statewideDataDomainPermissions.length > 0 ? (
            <table cellSpacing={0} className="page-view-table">
              <thead>
                <tr>
                  <th>Permission</th>
                </tr>
              </thead>
              <tbody>
                {userDetails.statewideDataDomainPermissions.map((role) => (
                  <tr key={`${role}`} data-testid={role}>
                    <td>{getRoleDisplayText(role)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="empty--list-text">
              <p>No data domains assigned</p>
            </div>
          )}
        </div>
      )}
      {userDetails.userType === UserType.District && (
        <div data-testid="district-list">
          {!isDetailLoading ? (
            <>
              {userDetails.isMultiDistrictUser && (
                <div className="multi-district-dropdown">
                  <StyledSingleDropdown
                    showLabel={true}
                    labelText="Related District for Data Domain Permissions"
                    fieldName="viewingDistrictId"
                    options={userDetails.multiDistrictOptions}
                    onChange={handleViewingDistrictChange}
                    value={setStyledDropdownSingleValue(
                      userDetails.multiDistrictOptions,
                      userDetails.viewingDistrictId
                    )}
                  />
                </div>
              )}

              <div className="list-display">
                <h2>Data Domain Permissions</h2>
                {userDetails.dataDomainPermissionsForViewingDistrict.length >
                0 ? (
                  <table cellSpacing={0} className="page-view-table">
                    <thead>
                      <tr>
                        <th>Permission</th>
                        <th>School(s)</th>
                        <th>Additional Permissions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userDetails.dataDomainPermissionsForViewingDistrict.map(
                        (assignment) => (
                          <tr
                            key={`${assignment.role}`}
                            data-testid={`row-${assignment.role}`}
                          >
                            <td>{getRoleDisplayText(assignment.role)}</td>
                            <td>{assignment.schools}</td>
                            <td data-testid="additional-permissions">
                              {assignment.additionalPermissions}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                ) : (
                  <div className="empty--list-text">
                    <p>No data domain permissions assigned</p>
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="detail-loading-container">
              <Loader
                dataTestId="detail-loader"
                message="Loading data domain permissions for district"
              />
            </div>
          )}
        </div>
      )}
    </NavigationPage>
  );
};

export default UserDetail;

import { ReactElement } from 'react';
// eslint-disable-next-line max-len
import { NotAbleToUploadPropTypes } from '../../../types/propTypes/NotAbleToUploadPropTypes';
import './NotAbleToUpload.css';

const NotAbleToUpload = ({
  files,
  errors,
  solutions,
  customTitle,
}: NotAbleToUploadPropTypes): ReactElement => {
  const title =
    customTitle ||
    (files.length === 1
      ? 'File will not be able to upload'
      : 'Some files will not be able to upload');

  return (
    <div className="files--upload--not-able-to-upload--no-file">
      <h3>{title}</h3>

      {!customTitle && ( // Conditionally render the list
        <ul className="files--upload--not-able-to-upload--files">
          {files.map((file, i: number) => (
            <li key={file.name}>
              <div>
                <span className="files--upload--not-able-to-upload--file-name">
                  {file.name}
                </span>
                <span className="files--upload--not-able-to-upload--error">
                  {errors[i]}
                </span>
              </div>
            </li>
          ))}
        </ul>
      )}
      {customTitle && ( // Conditionally render the list
        <ul className="files--upload--not-able-to-upload--no-files">
          {errors.map((error, i: number) => (
            <li key={error}>
              <div>
                <span className="files--upload--not-able-to-upload--error">
                  {errors[i]}
                </span>
              </div>
            </li>
          ))}
        </ul>
      )}

      <h3>How to fix this problem:</h3>
      <ul className="files--upload--not-able-to-upload--solutions">
        {solutions.map((solution, i: number) => (
          <li key={solution}>
            {solutions.length > 1 && <strong>Option {i + 1}:</strong>}{' '}
            {solution}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NotAbleToUpload;

/* eslint-disable max-len */
import { ReactElement } from 'react';
import Select, { components, OptionProps, SingleValue } from 'react-select';
import { StyledSingleDropdownPropTypes } from '../../types/propTypes/StyledSingleDropdownPropTypes';
import { ReactSelectOption } from '../../types/ReactSelectOption';
import FormInputLabel from '../FormInputs/FormInputLabel/FormInputLabel';
import './StyledSingleDropdown.css';

const StyledSingleDropdown = (
  props: StyledSingleDropdownPropTypes
): ReactElement => {
  const {
    fieldName,
    inputClasses,
    options,
    onChange,
    value,
    labelText,
    showLabel,
    disabled,
    isClearable,
  } = props;

  const handleValueChange = async (
    selected: SingleValue<ReactSelectOption>
  ): Promise<void> => {
    onChange && (await onChange(selected));
  };

  const OptionWithRole = (
    optionProps: OptionProps<ReactSelectOption>
  ): JSX.Element => {
    const innerProps = {
      ...optionProps.innerProps,
      role: 'option',
    };
    return <components.Option {...optionProps} innerProps={innerProps} />;
  };

  const configurationProps = { 'aria-label': labelText ?? fieldName };

  return (
    <div
      className="style-single-dropdown-container"
      data-testid={`styled-single-dropdown-${fieldName}`}
    >
      {showLabel && (
        <FormInputLabel field={fieldName} displayName={labelText} />
      )}
      <Select
        isMulti={false}
        {...configurationProps}
        {...props}
        name={fieldName}
        inputId={fieldName}
        components={{
          Option: OptionWithRole,
        }}
        classNames={{
          container: () => 'form-dropdown',
          control: () => (inputClasses ? inputClasses : `control`),
          valueContainer: () => 'value-container',
          singleValue: () => 'single-value',
          multiValue: () => 'multi-value',
          loadingIndicator: () => 'dropdown-loading',
          menu: () => 'dropdown-menu',
          option: () => 'dropdown-option',
        }}
        options={options.map((x) => ({
          value: x.value,
          label: x.text,
          isDisabled: x.isDisabled ?? false,
        }))}
        isDisabled={disabled}
        onChange={handleValueChange}
        value={value}
        isClearable={isClearable}
      />
    </div>
  );
};

export default StyledSingleDropdown;

import { orderBy } from 'lodash';
import { ReactElement, useEffect, useMemo } from 'react';
import Bubble from '../../components/Bubble/Bubble';
import DataQualityFilter from '../../components/DataQualityFilter/DataQualityFilter';
import EmptyGridContent from '../../components/EmptyGridContent/EmptyGridContent';
import NavigationPage from '../../components/NavigationPage/NavigationPage';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectDashboardItems,
  selectDataQualityLoading,
  selectDQDFilter,
} from '../../redux/slices/dataQualitySlice';
import { fetchDataQualityData } from '../../redux/thunks/dataQualityThunks';
import {
  abortPromiseOnUnmount,
  getCurrentUserAccess,
} from '../../services/base.service';
import { appRoutePaths } from '../../services/route.service';
import { DataQualityDashboardModel } from '../../types/DataQualityDashboardModel';
import { IconType } from '../../types/propTypes/EmptyGridPropTypes';
import './DataQualityDashboard.css';

const DataQualityDashboard = (): ReactElement => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectDataQualityLoading);
  const filter = useAppSelector(selectDQDFilter);
  const items: DataQualityDashboardModel = useAppSelector(selectDashboardItems);

  useEffect(() => {
    const promise = dispatch(fetchDataQualityData({ filter }));
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch, filter]);

  const sortedDisplayErrors = useMemo(
    () => orderBy(items.domainErrors, 'displayText', 'asc'),
    [items.domainErrors]
  );

  return (
    <NavigationPage
      pageClass="data-quality"
      heading={`${getCurrentUserAccess()?.currentDistrict?.name}`}
      isLoading={isLoading}
      loadingDataId="data-quality-loader"
      loadingText="Getting your items"
    >
      <DataQualityFilter
        dataCollectionValue={filter}
        dataCollectionOptions={items.dataCollectionOptions}
      />
      <div className="bubble-list">
        {sortedDisplayErrors.map((item, index) => (
          <Bubble
            key={index}
            count={item.errorCount}
            title={item.displayText}
            bubbleText="Error(s)"
            navigateTo={appRoutePaths.DataQualityDetail(item.domain)}
            visibilityOverrideRoute={appRoutePaths.DataQualityDetail()}
          />
        ))}
      </div>

      {sortedDisplayErrors.length === 0 && (
        <EmptyGridContent iconType={IconType.FILE}>
          <p>There are no errors</p>
        </EmptyGridContent>
      )}
    </NavigationPage>
  );
};

export default DataQualityDashboard;
